<!--
 * @Author: your name
 * @Date: 2021-02-24 16:43:11
 * @LastEditTime: 2021-03-11 16:54:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
-->
<template>
	<div>
		<div class="topTitle paddingL20 paddingT10">
			<el-col :span="12">
				<titleModule title="素材详情"></titleModule>
			</el-col>
			<el-col :span="12">
				<div class="handleBtn">
					<el-button size="medium" @click="$router.go(-1)">返回</el-button>
				</div>
			</el-col>
		</div>
		<div class="Wrapper paddingL20 paddingB10">
			<el-form
				label-position="left"
				label-width="100px"
				:model="formInline"
				ref="ruleForm">
				<el-form-item label="名称:">
					<span>{{formInline.materialName}}</span>
				</el-form-item>
				<el-form-item label="播放时长:">
					<span>{{formInline.playTime}}秒</span>
				</el-form-item>
				<el-form-item label="屏幕尺寸:">
					<span>{{ formInline.screenWidth }}*{{formInline.screenHeight}}</span>
					<div
						class="screen"
						:style="{width: formInline.screenWidth + 'px', height: formInline.screenHeight + 'px'}">
						<div v-if="formInline.materialType === 1">
							<div
								class="berth"
								v-for="(item, index) in formInline.berthList"
								:key="index"
								:style="{top: item.textY + 'px',
												left: item.textX + 'px',
												fontSize: item.textFontSize + 'px'}">
							</div>
						</div>
						<div
							class="imgPreview"
							v-if="formInline.materialType === 1 || formInline.materialType === 2 || formInline.materialType === 10"
							:style="{left: formInline.mediaX + 'px',
											top: formInline.mediaY + 'px'}">
							<img :src="mediaResourceUrl"
								:width="formInline.mediaShowWidth"
								:height="formInline.mediaShowHeight" alt=""/>
						</div>
						<div
							class="imgPreview"
							v-if="formInline.materialType === 4 || formInline.materialType === 12"
							:style="{left: formInline.mediaX + 'px',
											top: formInline.mediaY + 'px'}">
							<video
								:src="mediaResourceUrl"
								:width="formInline.mediaShowWidth"
								:height="formInline.mediaShowHeight"
								controls
								autoplay></video>
						</div>
						<div
								class="text"
								v-if="formInline.materialType === 8 || formInline.materialType === 12 || formInline.materialType === 10"
								:style="{left: formInline.textX + 'px',
													top: formInline.textY + 'px',
													width: formInline.textShowWidth + 'px',
													height: formInline.textShowHeight + 'px',
													lineHeight: 1,
													fontFamily: textFont,
													fontSize: formInline.textFontSize + 'px',
													color: styleFontColor,
													textAlign: styleTextAlign,
													fontWeight: formInline.textFontStyle === 1 ? 600 : 400,
													fontStyle: formInline.textFontStyle === 2 ? 'italic' : 'normal',
													textDecoration: formInline.textFontStyle === 4 ? 'underline' : 'normal'}">
								{{formInline.textContent}}
						</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
import titleModule from "@/components/titleModule/titleModule";

export default {
	name: "materialDetail",
	data() {
		return {
			formInline: {
				materialName: '',
				screenWidth: 0,
				screenHeight: 0,
				playTime: '',
				berthList: []
			},
		}
	},
	components: {
		titleModule
	},
	computed: {
		textFont() {
			const { textFont } = this.formInline;
			let obj = {
				"1": "SimHei",
				"2": "KaiTi",
				"3": "SimSun",
				"4": "STFangsong",
				"5": "LiSu"
			}
			return obj[textFont] || 'Times New Roman'
		},
		styleFontColor() {
			const { textFontColor } = this.formInline;
			let obj = {
				"1": "#ff0000",
				"2": "#008000",
				"3": "#0000ff",
				"4": "#ffff00",
				"5": "#ee82ee",
				"6": "#00ffff",
				"7": "#ffffff",
				"8": "#000000"
			}
			return obj[textFontColor]
		},
		styleTextAlign() {
			const { textAlignment } = this.formInline;
			let obj = {
				"0": "left",
				"1": "right",
				"2": "center"
			}
			return obj[textAlignment]
		}
	},
	created() {
		if (this.$route.query.materialId) {
			const data = this.$route.query;
			this.formInline.materialName = data.materialName;
			this.formInline.materialType = Number(data.materialType) || 1;
			this.formInline.screenWidth = data.screenWidth || 0;
			this.formInline.screenHeight = data.screenHeight || 0;
			this.formInline.mediaX = data.mediaX || 0;
			this.formInline.mediaY = data.mediaY || 0;
			this.formInline.mediaShowHeight = data.mediaShowHeight || 0;
			this.formInline.mediaShowWidth = data.mediaShowWidth || 0;
			this.formInline.textX = data.textX || 0;
			this.formInline.textY = data.textY || 0;
			this.formInline.textShowWidth = data.textShowWidth || 0;
			this.formInline.textShowHeight = data.textShowHeight || 0;
			this.formInline.textFont = Number(data.textFont) || 1;
			this.formInline.textFontSize = Number(data.textFontSize) || 16;
			this.formInline.textFontColor = Number(data.textFontColor) || 1;
			this.formInline.textFontStyle = Number(data.textFontStyle) || 0;
			this.formInline.textAlignment = Number(data.textAlignment) || 0;
			this.formInline.textPlayEffects = Number(data.textPlayEffects) || 0;
			this.formInline.textEffectsSpeed = Number(data.textEffectsSpeed) || 2;
			this.formInline.textContent = data.textContent || '';
			this.formInline.berthList = data.berthList || [
				{
					textX: 0,
					textY: 0,
					textShowHeight: 0,
					textShowWidth: 0,
					textFont: 1,
					textFontSize: 16,
					berthNumUrl: ""
				}
			];
			this.formInline.playTime = data.playTime;
			this.mediaResourceUrl = data.mediaResourceUrl;
		}
	}
}
</script>
<style lang="stylus" scoped>
.topTitle
	background-color #ffffff
	height 40px
.Wrapper
	background-color #ffffff
	.screen
		position relative
		border 1px solid #000
		.berth
			position absolute
			z-index 2
		.imgPreview
			position absolute
			z-index 1
		.text
			position absolute
			width 100%
			overflow hidden
			z-index 2
</style>
